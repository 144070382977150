import React, { useEffect, useState } from "react";
import { Table} from "reactstrap";

import { todayAppointment } from "../_helper/apiCall/leadServices";
import Aos from "aos";

function Todayappointment() {
  const [leads, setLeads]=useState()
  useEffect(() => {
    Aos.init({ duration: 1000 });
  (async ()=>{
    const data= await todayAppointment()
    if(data?.status==1){
      setLeads(data?.data)
    }else{
      setLeads([])
    }
  })();
  }, []);
  return (
    <div className="table ">
        <h5 className=" text-center">Today Appointment</h5>
      <Table striped responsive>
        <thead>
          <tr className="text-center">
            <th>S.No.</th>
            <th>Name</th>
            <th>Treatment</th>
            <th>Phone</th>
            <th>Location</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
        {leads?.map((i,index)=>(
          <tr>
          <td>{index+1}</td>
          <td>{i.name}</td>
          <td>{i.queries}</td>
          <td>{i.phone}</td>
          <td>{i.country},{i.state},{i.city}</td>
          <td>{i.comment}</td>
        </tr>
        )

        )}
        </tbody>
      </Table>
    </div>
  );
}

export default Todayappointment;
