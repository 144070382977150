import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import Iframe from "react-iframe";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { Carddata } from "../_helper/apiCall/leadServices";

function Allcards() {
  const [homedata, setHomedata] = useState();
  useEffect(() => {
    Aos.init({ duration: 1000 });
    (async () => {
      const data = await Carddata();
      if (data) {
        setHomedata(data?.data);
       
      } else {
        setHomedata([]);
      }
    })();
  }, []);
  return (
    <>
      <Row xs="1" md="2" sm="2">
        <Col>
          <Row xs="1" md="2" sm="2">
            <Col>
              <Card className="shadow mb-3 rounded" data-aos="fade-right">
                <CardBody>
                  <CardTitle tag="h5" className="text-center">
                    Lead Data
                  </CardTitle>
                  <CardText>
                    Today Lead: <span>{homedata?.todayLeads}</span>
                  </CardText>
                  <CardText>
                    Total Lead: <span>{homedata?.totalLead}</span>
                  </CardText>
                  <Link to="/home/add-Leads">
                    <Button className="all-btn">Add Lead</Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className=" shadow-lg mb-3 rounded" data-aos="fade-up">
                <CardBody>
                  <CardTitle tag="h5" className="text-center">
                    FOLLOW UP
                  </CardTitle>
                  <CardText>
                    Today Follow Up Leads: <span>{homedata?.todayFollowup}</span>
                  </CardText>
                  <CardText>
                    Total Relevent Leads: <span>{homedata?.totalRelevent}</span>
                  </CardText>
                  <Link to="/follow-up">
                    <Button className="all-btn">Followup</Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col data-aos="fade-left">
          <Iframe
            className="shadow mb-5"
            url="/today-appointment"
            width="100%"
            height="185px"
            position="relative"
          />
        </Col>
      </Row>
    </>
  );
}

export default Allcards;
