import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Home from "./pages/home";
import Followup from "./pages/followup";
import Giveaccess from "./pages/give_access";
import AddDeal from "./pages/addDeal";
import Myprofile from "./pages/myprofile";
import Assignleads from "./components/assignleads";
import Todayappointment from "./common/today_appontment";
import { getSelfUser, getUsers } from "./_helper/apiCall/userData";
import { useEffect } from "react";
import Dealform from "./common/dealform";
import DailyReport from "./pages/daily_report";

function App() {
  useEffect(() => {
    const token=localStorage.getItem("authToken");
    (async()=>{
      // if(token)
      await getSelfUser();
    })();
    
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/home/add-leads" element={<AddDeal />}></Route>
          <Route index element={<Login />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/follow-up" element={<Followup />}></Route>
          <Route path="/give-access" element={<Giveaccess />}></Route>
          <Route path="/my-profile" element={<Myprofile />}></Route>
          <Route path="assign-leads" element={<Assignleads />}></Route>
          <Route path="today-appointment" element={<Todayappointment />}></Route>
          <Route path="edit-lead/:id" element={<Dealform />}></Route>
          <Route path="daily-report" element={<DailyReport />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
