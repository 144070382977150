import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import logo from "../images/logo.png";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { setSession } from "../_helper/apiConfig/api_auth";
import { getUserlocal } from "../_helper/apiConfig/api_auth";

const Navbar2 = ({ direction, ...args }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [authUser, setAuthUser] = useState(getUserlocal());
  const toggle = () => setIsOpen(!isOpen);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle2 = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <Navbar
        className="shadow mb-5 rounded"
        fixed="top"
        sticky="top"
        color="light"
        light
        expand="md"
      >
        <NavbarBrand tag={Link} to="/home">
          <img width="150px" src={logo} alt="CMK Healthcare" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mx-auto  " navbar>
            <NavItem className="nav-links">
              <NavLink tag={Link} to="/home">
                Home
              </NavLink>
            </NavItem>
            <NavItem className="nav-links">
              <NavLink tag={Link} to="/home/add-leads">
                Add Leads
              </NavLink>
            </NavItem>
            <NavItem className="nav-links">
              <NavLink tag={Link} to="/follow-up">
                Follow up
              </NavLink>
            </NavItem>
            <NavItem className="nav-links">
              <NavLink className="nav-link" tag={Link} to="/daily-report">
                Daily Report
              </NavLink>
            </NavItem>
            {authUser?.isAdmin && (
              <>
                <NavItem className="nav-links">
                  <NavLink tag={Link} to="/give-access">
                    Access
                  </NavLink>
                </NavItem>

                <NavItem className="nav-links">
                  <NavLink tag={Link} to="/assign-leads">
                    Assign Leads
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle2}
            direction={direction}
            style={{ backgroundColor: "red !imortant" }}
          >
            <DropdownToggle caret style={{ backgroundColor: "red !imortant" }}>
              <FaUserCircle />
            </DropdownToggle>
            <DropdownMenu {...args}>
              <DropdownItem header>{authUser.name}</DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.preventDefault();
                  setSession();
                }}
              >
                Log out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Collapse>
      </Navbar>
    </>
  );
};

export default Navbar2;
