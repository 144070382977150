import {React} from "react"
import { FadeLoader } from "react-spinners";


function LoadingSpinner() {
    return(
        <div className="login-container ">
        <div className="justify-content-center align-items-center">
        <FadeLoader size={100} color="#045d6c" />
     </div>
    </div>
    )
}

export default LoadingSpinner;