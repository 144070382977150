import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Col,
  Row,
  ButtonGroup,
  Input,
} from "reactstrap";
import Reschedule from "../components/reschedule";
import Searchlist from "../components/searchlist";
import Aos from "aos";
import "aos/dist/aos.css";
import { AllLead, UpdateUser } from "../_helper/apiCall/leadServices";
import moment from "moment";
import LoadingSpinner from "./loading_spinner";
import ReactPaginate from "react-paginate";
import Detailedview from "../components/detailedview";
import { toast } from "react-toastify";
import { getUsers } from "../_helper/apiCall/userData";
import { getUserlocal } from "../_helper/apiConfig/api_auth";
import { useNavigate } from "react-router-dom";


function Userdata(props) {
  const [loading, setLoading] = useState(false);
  const [leadsdata, setLeadsdata] = useState([]);
  const [modal4, setModal4] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [searchvalue, setSearchvalue] = useState();
  const [modal, setModal] = useState(false);
  const [authUser] = useState(getUserlocal());
  const [modal2, setModal2] = useState(false);
  const [page, setPage] = useState(0);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageLimit, setPageLimit] = useState(15);
  const [startdate, setStartdate] = useState("2022-01-01");
  const [enddate, setEnddate] = useState(moment().format("YYYY-MM-DD"));
  const [username, setUsername] = useState();
  const [user, setUser] = useState([]);
  const [statusname, setStatusname] = useState();
  const [sourcename, setSourcename] = useState();
  const [followupdate, setFollowupdate] = useState()
  const [filter, setFilter] = useState("");
  const [cardName, setCardName] = React.useState("card");
  const [leaduser, setleadUser] = useState();
  const [selectedItems, setSelectedItems] = useState([]);

  const AssignSubmit = async (e) => {
    if (selectedItems == 0) {
      toast.error("Please select leads");
      return;
    }
    e.preventDefault();
    let obj = {
      arrayofId: selectedItems,
      user: leaduser,
    };
    if (!leaduser) {
      toast.error("Please select User");
      return;
    }
    let res = await UpdateUser(obj);
    if (res?.status === 1) {
      window.location.reload();
      setModal4(false);
      toast.success(res.message);
      setModal4(false);
      return;
    }
    setModal4(false);
    toast.error(res?.message);
    return;
  };
  useEffect(() => {
    (async () => {
      let res = await getUsers();
      if (res?.data) {
        setUser(
          res?.data
            ?.filter((i) => !i.isAdmin)
            .map((i) => ({ label: i.name, value: i.email }))
        );
      }
    })();

    Aos.init({ duration: 1000 });
    const fetchData = async () => {
      setLoading(true);
      const data = await AllLead(statusname, page + 1, filter);
      if (data?.status === 1) {
        setLoading(false);
        setLeadsdata(data?.data);
        setPageTotal(data.totalCount);
      } else {
        setLoading(false);
        setLeadsdata([]);
      }
    };
    fetchData();
  }, [statusname, page, filter]);

  useEffect(() => {
    let filterString = "";
    if (searchvalue) {
      filterString += `&q=${searchvalue}`;
    }
    if (startdate) {
      filterString += `&startDate=${startdate}`;
    }
    if (enddate) {
      filterString += `&endDate=${enddate}`;
    }
    if (sourcename) {
      filterString += `&source=${sourcename}`;
    }
    if (followupdate) {
      filterString += `&followupDate=${followupdate}`;
    }
    if (username) {
      filterString += `&createuser=${username}`;
    }
    setFilter(filterString);
  }, [searchvalue, startdate, enddate, username,followupdate,sourcename]);

  const toggleModal = () => setModal(!modal);
  const toggleModal2 = () => setModal2(!modal2);
  const toggle4 = () => setModal4(!modal4);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setPage(selectedPage);
    sendPageInfo(selectedPage, selectedPage + 1);
  };
  const sendPageInfo = (index, page) => {
    console.log(`Index: ${index}, Page: ${page}`);
  };
  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

 
 
  return (
    <>
      <Searchlist
        searchvalue={searchvalue}
        setSearchvalue={setSearchvalue}
        startdate={startdate}
        setStartdate={setStartdate}
        enddate={enddate}
        setEnddate={setEnddate}
        followupdate={followupdate}
        setFollowupdate={setFollowupdate}
        username={username}
        setUsername={setUsername}
        sourcename={sourcename}
        setSourcename={setSourcename}
        statusname={statusname}
        setStatusname={setStatusname}
      />
          {authUser?.isAdmin && (
      <>
    <Button
      color="success"
      onClick={(e) => {
        e.preventDefault();
        toggle4();
      }}
      size="sm"
    >
      Assign Lead
    </Button>
    </>
       )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="sticky-table-container">
          {leadsdata && leadsdata.length > 0 ? (
            <>
              <Table striped responsive data-aos="fade-up">
                <thead>
                  <tr className="text-center">
                    <th>S.No.</th>
                    <th>Assign_Date</th>
                    {authUser?.isAdmin && <th>Agent Name</th>}
                    <th>Patient_Name</th>
                    <th>Treatment</th>
                    <th>Age</th>
                    <th>Phone</th>
                    <th>Source</th>
                    <th>Location</th>
                    <th>Comment</th>
                    <th>Status</th>
                    <th>Followup Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {leadsdata.map((lead, index) => (
                    <tr key={lead.id}>
                      <td>
                        {index + 1 + page * pageLimit}
                        {authUser?.isAdmin && (
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(lead.id)}
                            onChange={() => handleCheckboxChange(lead.id)}
                          />
                        )}
                      </td>
                      <td>
                        {lead.createdAt
                          ? moment(lead.createdAt).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      {authUser?.isAdmin && <td>{lead.user}</td>}
                      <td>{lead.name}</td>
                      <td>{lead.queries}</td>
                      <td>{lead.age}</td>
                      <td>{lead.phone}</td>
                      <td>{lead.source}</td>
                      <td>{`${lead.country} ${lead.state} ${lead.city}`}</td>
                      <td>{lead.comment}</td>
                      <td>{lead.status}</td>
                      <td>{lead.followup_date}</td>
                      <td>
                        <ButtonGroup>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setId(lead.id);
                              toggleModal();
                            }}
                            color="primary"
                            size="sm"
                          >
                            Action
                          </Button>
                          <Button
                            color="success"
                            onClick={(e) => {
                              e.preventDefault();
                              setId(lead.id);
                              toggleModal2();
                            }}
                            size="sm"
                          >
                            View
                          </Button>
                          <Button
                            color="info"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/edit-lead/${lead.id}`);
                            }}
                            size="sm"
                          >
                            Edit
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {authUser?.isAdmin && (
                <>
              <div className="scene scene--card">
                <div
                  className={cardName}
                  onClick={() => setCardName("card is-flipped")}
                >
                  <div className="card__face card__face--front">
                    Total Lead:{" "}
                  </div>
                  <div className="card__face card__face--back">{pageTotal}</div>
                </div>
              </div>
              </>
                 )}
            </>
          ) : (
            <>No Record Found</>
          )}
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col md={6}>
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="Next>"
                  onPageChange={handlePageClick}
                  initialPage={page}
                  pageCount={Math.ceil(pageTotal / pageLimit)}
                  previousLabel="<Previous"
                />
              </Col>
            </Row>
          </Container>
          <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
            <ModalHeader toggle={toggleModal} className="adddeal-popup">
              Reschedule Deal
            </ModalHeader>
            <ModalBody className="adddeal-popup">
              <Reschedule id={id} setModal={setModal} />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={modal2}
            toggle={toggleModal2}
            size="lg"
            style={{ maxWidth: "70%", width: "100%" }}
            backdrop="static"
          >
            <ModalHeader toggle={toggleModal2} className="adddeal-popup">
              Reschedule Deal
            </ModalHeader>
            <ModalBody className="adddeal-popup">
              <Detailedview id={id} />
            </ModalBody>
          </Modal>
          <Modal isOpen={modal4} toggle={toggle4} backdrop="static">
            <ModalHeader toggle={toggle4} className="adddeal-popup">
              Assign Leads
            </ModalHeader>
            <ModalBody className="adddeal-popup">
              <Input
                id="username  "
                name="user-name"
                type="select"
                value={props?.username}
                onChange={(e) => {
                  setleadUser(e.target.value);
                }}
                autoComplete="off"
              >
                {user.map((i) => (
                  <option value={i.value}>{i.label}</option>
                ))}
              </Input>
              <Button className="mt-4" onClick={AssignSubmit}>
                Assign
              </Button>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
}

export default Userdata;
