import React, { useState, useEffect } from "react";
import { Input, Row, Col, Button, FormGroup, Label } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import { getUsers } from "../_helper/apiCall/userData";
import { getUserlocal } from "../_helper/apiConfig/api_auth";

function Searchlist(props) {
  const [user, setUser] = useState([{ label: "All User", value: "" }]);
  const [authUser, setAuthUser] = useState(getUserlocal());

  const sourceoption = [
    { value: "", label: "All Source" },
    { value: "Phone Call", label: "Phone Call" },
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "Website", label: "Website" },
    { value: "Message", label: "Message" },
    { value: "Mail", label: "Mail" },
    { value: "Reference", label: "Reference" },
    { value: "Campain", label: "Campain" },
    { value: "Others", label: "Others" },
  ];
  const statusoption = [
    { value: "", label: "All Leads" },
    { value: "New", label: "New Leads" },
    { value: "F", label: "Follow up" },
    { value: "R", label: "Relevent" },
    { value: "CallNA", label: "Not Answered" },
    { value: "AptF", label: "Appointment Fix" },
    { value: "AptC", label: "Appointment Cancel" },
    { value: "Apt", label: "Appointment Done" },
    { value: "SurgF", label: "Surgery Fix" },
    { value: "W", label: "Surgery Done" },
    { value: "L", label: "Irrelevent" },
  ];

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  useEffect(() => {
    (async () => {
      let res = await getUsers();
      if (res?.data) {
        setUser([
          { label: "All User", value: "" },
          ...res?.data
            ?.filter((i) => !i.isAdmin)
            .map((i) => ({ label: i.name, value: i.email })),
        ]);
      }
    })();
  }, []);
  return (
    <Row xs={8} md={8} sm={8} className="m-3" data-aos="fade-up">
      <Col>
      <Label>Assign date From</Label>
        <Input
          type="date"
          name="date1"
          value={props?.startdate}
          onChange={(e) => {
            props?.setStartdate(e.target.value);
          }}
          autoComplete="off"
        />
      </Col>
      <Col >
      <Label>Assign date To</Label>
        <Input
          type="date"
          name="date2"
          value={props?.enddate}
          onChange={(e) => {
            props?.setEnddate(e.target.value);
          }}
          autoComplete="off"
        />
      </Col>
      <Col >
      <Label>Followup Date</Label>
   <Input
     type="date"
     name="date3"
     value={props?.followupdate}
     onChange={(e) => {
       props?.setFollowupdate(e.target.value);
     }}
     autoComplete="off"
   />
 </Col>
      <Col>
      <Label>Status</Label>
        <Input
          id="statusname  "
          name="status-name"
          type="select"
          value={props?.statusname}
          onChange={(e) => {
            props?.setStatusname(e.target.value);
          }}
          autoComplete="off"
        >
          {statusoption.map((i) => (
            <option value={i.value}>{i.label}</option>
          ))}
        </Input>
      </Col>
      <Col>
      <Label>Source</Label>
        <Input
          id="sourcename  "
          name="source-name"
          type="select"
          value={props?.sourcename}
          onChange={(e) => {
            props?.setSourcename(e.target.value);
          }}
          autoComplete="off"
        >
          {sourceoption.map((i) => (
            <option value={i.value}>{i.label}</option>
          ))}
        </Input>
      </Col>
      {authUser?.isAdmin && (
        <Col className="mb-1">
           <Label>User</Label>
          <Input
            id="username  "
            name="user-name"
            type="select"
            value={props?.username}
            onChange={(e) => {
              props?.setUsername(e.target.value);
            }}
            autoComplete="off"
          >
            {user.map((i) => (
              <option value={i.value}>{i.label}</option>
            ))}
          </Input>
        </Col>
      )}
      <Col className="mb-1">
      <Label>Name / Number</Label>
        <Input
          type="text"
          name="serchvalue"
          value={props?.searchvalue}
          onChange={(e) => {
            props?.setSearchvalue(e.target.value);
          }}
          placeholder="Name / Phone"
          autoComplete="off"
        />
      </Col>
    </Row>
  );
}

export default Searchlist;
