import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Row, Table } from "reactstrap";
import { Getbyid } from "../_helper/apiCall/leadServices";
import { Form, useNavigate } from "react-router-dom";
import { getUserlocal } from "../_helper/apiConfig/api_auth";

function Detailedview(props) {
  const [authUser, setAuthUser] = useState(getUserlocal());
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [age, setAge] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [comment, setComment] = useState();
  const [followup_date, setFollowup_date] = useState();
  const [queries, setQueries] = useState();
  const [source, setSource] = useState();
  const [leadstatus, setLeadstatus] = useState("F");
  const [user, setUser] = useState();
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    (async () => {
      const data = await Getbyid(props.id);

      if (data) {
        setName(data?.data?.name);
        setAge(data?.data?.age);
        setEmail(data?.data?.email);
        setPhone(data?.data?.phone);
        setQueries(data?.data?.queries);
        setLeadstatus(data?.data?.status);
        setSource(data?.data?.source);
        setComment(data?.data?.comment);
        setFollowup_date(data?.data?.followup_date);
        setUser(data?.data?.user);
        setCity(data?.data?.city || "Select City");
        setCountry(data?.data?.country || "Select country");
        setState(data?.data?.state || "Select state");
        setHistoryData(data?.data?.all_user_data_histories);
      }
      console.log(historyData);
    })();
  }, [props.id]);
  const statusoption2 = {
    F: "Follow up",
    R: "Relevent",
    AptF: "Appointment Fix",
    AptC: "Appointment Cancel",
    Apt: "Appointment Done",
    SurgF: "Surgery Fix",
    W: "Surgery Done",
    L: "Irrelevent",
  };

  return (
    <>
      {historyData && historyData.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>S.No. </th>
              {authUser?.isAdmin && 
              <th>User</th>}
              <th>Status </th>
              <th>Date </th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {historyData.map((i, index) => (
              <tr>
                <td>{index + 1}</td> {authUser?.isAdmin && <td>{i.user}</td>}
                <td>{statusoption2[i.status]}</td>
                <td>{i.followup_date}</td>
                <td>{i.comment}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <>No record found</>
      )}
    </>
  );
}

export default Detailedview;
