import React, { useEffect, useState } from "react";
import { Container, Input, Table, Button, Row, Col } from "reactstrap";
import Navbar2 from "../components/navbar";
import { getUserlocal } from "../_helper/apiConfig/api_auth";
import { getUsers } from "../_helper/apiCall/userData";
import ReactPaginate from "react-paginate";
import { DailyReportData } from "../_helper/apiCall/leadServices";
import LoadingSpinner from "../common/loading_spinner";
import { useBeforeUnload } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

function DailyReport(props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([{ label: "All", value: "" }]);
  const [username, setUsername] = useState(!getUserlocal()?.isAdmin?getUserlocal()?.email:"");
  const [page, setPage] = useState(0);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);
  const [authUser, setAuthUser] = useState(getUserlocal());
  const [reportdate, setReportdate] = useState("");
  const [leadsdata, setLeadsdata] = useState();
  useEffect(() => {
    (async () => {
      let res = await getUsers();
      if (res?.data) {
        setUser([
          { label: "All User", value: "" },
          ...res?.data
            ?.filter((i) => !i.isAdmin)
            .map((i) => ({ label: i.name, value: i.email })),
        ]);
      }
    })();
  }, []);
useEffect(()=>{
  if(!authUser?.isAdmin){
    setUsername(authUser.email)
  }else{
    setUsername()
  }
},[authUser])
  const fetchData = async () => {
    setLoading(true);
    const data = await DailyReportData(username, reportdate, page);
    if (data?.status === 1) {
      setLoading(false);
      setLeadsdata(data?.data);
      setPageTotal(data.totalCount);
    } else {
      setLoading(false);
      setLeadsdata([]);
    }
  };
  useEffect(() => {
    fetchData();
  }, [username, reportdate, page]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setPage(selectedPage);
    sendPageInfo(selectedPage, selectedPage + 1);
  };
  const sendPageInfo = (index, page) => {
    console.log(`Index: ${index}, Page: ${page}`);
  };

  const statusoption2 = {
    F: "FollowUp",
    R: "Relevent",
    AptF: "Appointment Fix",
    AptC: "Appointment Cancel",
    Apt: "Appointment Done",
    SurgF: "Surgery Fix",
    W: "Surgery Done",
    L: "Irrelevent",
  }

  return (
    <>
      <HelmetProvider>
    <Helmet>
      <title>Daily Report</title>
    </Helmet>
  </HelmetProvider>
      <Navbar2 />
      <Container>
        <Row className="m-4">
          <Col>
            <Input
              name="reportdate"
              value={reportdate}
              onChange={(e) => {
                setReportdate(e.target.value);
              }}
              type="date"
            />
          </Col>
          {authUser?.isAdmin && (
            <Col>
              <Input
                id="username  "
                name="user-name"
                type="select"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                autoComplete="off"
              >
                {user.map((i) => (
                  <option value={i.value}>{i.label}</option>
                ))}
              </Input>
            </Col>
          )}
        </Row>
      </Container>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="sticky-table-container">
            {leadsdata && leadsdata.length > 0 ? (
              <Table striped responsive >
                <thead>
                  <tr className="text-center">
                    <th>S.No.</th>
                    <th>Patient_Name</th>
                    <th>Treatment</th>
                    <th>Age</th>
                    <th>Phone</th>
                    <th>Source</th>
                    <th>Location</th>
                    <th>Comment</th>
                    <th>Followup Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {leadsdata && leadsdata.length > 0 ? (
                    leadsdata.map((i, index) => (
                      <tr>
                        <td>{index + 1 + page * pageLimit}</td>
                        <td>{i.name}</td>
                        <td>{i.queries}</td>
                        <td>{i.age}</td>
                        <td>{i.phone}</td>
                        <td>{i.source}</td>
                        <td>{`${i.country} ${i.state} ${i.city}`}</td>
                        <td>{i.comment}</td>
                        <td>{i.followup_date}</td>
                        <td>{statusoption2[i.status]}</td>
                      </tr>
                    ))
                  ) : (
                    <>No record found</>
                  )}
                </tbody>
              </Table>
            ) : (
              <>No Record Found</>
            )}
          </div>
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col md={6}>
                <ReactPaginate
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  breakLabel="..."
                  nextLabel="Next>"
                  onPageChange={handlePageClick}
                  initialPage={page}
                  pageCount={Math.ceil(pageTotal / pageLimit)}
                  previousLabel="<Previous"
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default DailyReport;
