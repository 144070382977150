import { setUser } from "../apiConfig/api_auth";
import RestMethod from "./rest_Methods";

export const getUsers = async()=>{
    let url="/users";
    try{
        const response = await RestMethod.GET(url);
        return response?.data;

    }catch(error){
        console.log("error detected while fetching data from api", error)
        return null;
    }
};

export const getSelfUser = async()=>{
    let url="self/user";
    try{
        const response = await RestMethod.GET(url);
        if(response?.data?.status==1){
            setUser(JSON.stringify(response.data.data))
        }
        return response?.data;

    }catch(error){
        console.log("error detected while fetching data from api", error)
        return null;
    }
};
