import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import { FaUser, FaLock ,FaEye} from "react-icons/fa"; // Importing icons from React Icons
import { MdEmail } from "react-icons/md";
import { FaPhone , FaEyeSlash} from "react-icons/fa6";
import drimage from "../images/home1.webp";
import Navbar2 from "../components/navbar";
import { setSession } from "../_helper/apiConfig/api_auth";
import { Helmet , HelmetProvider} from "react-helmet-async";

function Signup() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [cpassword, setCpassword] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform login logic here, like sending data to a server
  };

  return (
    <>
        <HelmetProvider>
  <Helmet>
    <title>
      CMK Profile
    </title>
  </Helmet>
</HelmetProvider>
      <Navbar2 />
      <Container data-aos="fade-up" style={{ width: "99%" }}>
        {/* <CardHeader className="text-center">My Profile</CardHeader> */}

        <Row>
          {/* <Col> */}
            {/* <img src={drimage} alt="Dr. Image"></img> */}
          {/* </Col> */}
          <Col className="justify-content-center align-items-center" >
            <h1 className="text-center">My Profile</h1>
            <Row className="m-3 " >
              <Label for="name" className="input-label">
                <FaUser className="icon" /> Name
              </Label>

              <Col>
                <Input
                  type="text"
                  id="name"
                  placeholder="Enter your Full Name"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              <Col>
                <Button className="m-1 all-btn">save</Button>
              </Col>
            </Row>
            <Row className="m-3">
              <Label for="phone" className="input-label">
                <FaPhone className="icon" /> Phone
              </Label>

              <Col>
                <Input
                  type="number"
                  id="phone"
                  placeholder="Enter your Phone Number"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Col>
              <Col></Col>
            </Row>
            <Row className="m-3">
              <Label for="email" className="input-label">
                <MdEmail className="icon" /> Email
              </Label>
              <Col>
                <Input
                  type="email"
                  id="email"
                  placeholder="Enter your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              <Col></Col>
            </Row>
            <Row className="m-3">
              <Label for="password" className="input-label">
                <FaLock className="icon" /> Password
              </Label>
              <Col>
                <Input
                  type="password"
                  id="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Col>
              <Col>
                <FaEye style={{color:"#045d6c", fontSize:"larger"}}/>
                <FaEyeSlash style={{color:"#045d6c", fontSize:"larger"}} />
              </Col>
            </Row>
            <Row className="m-3">
              <Label for="Cpassword" className="input-label">
                <FaLock className="icon" /> Confirm Password
              </Label>
              <Col>
                <Input
                  type="password"
                  id="cpassword"
                  placeholder="Confirm Your Password"
                  value={cpassword}
                  onChange={(e) => setCpassword(e.target.value)}
                />
              </Col>
              <Col>
                <Button className="m-1 all-btn">change</Button>
              </Col>
            </Row>
          </Col>
        </Row>
       <Button style={{marginLeft:"60%"}} onClick={(e)=>{e.preventDefault()
      setSession()}} className="all-btn">Log out</Button>
      </Container>
    </>
  );
}

export default Signup;
