import React, { useEffect, useState } from "react";
import {Chart} from "react-google-charts"
import { countForbar } from "../_helper/apiCall/leadServices";


export const Bdata = [
    ["Month", "Total", "Won", "Lost"],
    ["Jan", 0, 0, 0],
    ["Feb", 0, 0, 0],
    ["Mar", 0, 0, 0],
    ["Apr", 0, 0, 0],
    ["May", 0, 0, 0],
    ["Jun", 0, 0, 0],
    ["Jul", 0, 0, 0],
    ["Aug", 0, 0, 0],
    ["Sep", 0, 0, 0],
    ["Oct", 0, 0, 0],
    ["Nov", 0, 0, 0],
    ["Dec", 0, 0, 0],
  ];
  
  export const Boptions = {
    chart: {
      title: "Company Performance",
      subtitle: "Sales, Expenses, and Profit: 2024",      
    },
    series: {
      0: { color: '#045d6c'},
      1: { color: 'green' },
      2: { color: '#e2431e' },
    }
  };

function Barchart() {
  const[data,setData]=useState([...Bdata])
  useEffect(()=>{
    (async()=>{
     const res= await countForbar()
     console.log("res222222222222222222222",res);
     if(res){
     setData([
      ["Month", "Total", "Won", "Lost"],
      ...res.data
    ])}
    })()
  },[])
  console.log("data",data);
  
    return(
        <Chart 
        chartType="Bar"
        width={"100%"}
        height={"500px"}
        data={data}
        options={Boptions}
        />
    )
}

export default Barchart;

