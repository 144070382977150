import axios from "axios";
import { toast } from 'react-toastify';
import {URL_CONFIG} from "../../_constant/config/url_config"

const API_AUTH=axios. create({
    baseURL: URL_CONFIG.DEV_URL,
});

API_AUTH.interceptors.request.use(function (request){
    const token=localStorage.getItem("authToken");
    request.headers.Authorization= token ?`Bearer ${token}` : "";
return request
});


API_AUTH.interceptors.response.use((response) =>{
    if(response.data.status===401){
        localStorage.removeItem("authToken")
        localStorage.removeItem("auth_user");
        if(!window.location.href.includes("/login")){
            window.location.assign("/login");
        }
    }
    return response;
});


API_AUTH.interceptors.response.use(undefined, (error)=>{
    if(error?.response?.status===401){
        toast("unauthorized")
        localStorage.removeItem("authToken")
        localStorage.removeItem("auth_user");
        if(!window.location.href.includes("/login")){
            window.location.assign("/login");
        }
    }
    return Promise.reject(error);
});

export default API_AUTH;

export const setSession=(token)=>{
    if(token){
localStorage.setItem("authToken", token)
axios.defaults.headers.common["Authorization"]="Bearer" + token;
    }else{
localStorage.removeItem("authToken");
localStorage.removeItem("auth_user");
delete axios.defaults.headers.common["Authorization"];
window.location.assign("/login");
    }
};

export const setUser=(user)=>{
    if(user){
localStorage.setItem("auth_user", user)
    }else{
localStorage.removeItem("auth_user");
    }
};

export const getUserlocal=()=>{
    return JSON.parse(localStorage.getItem("auth_user"))
   
};