import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Button, Label } from "reactstrap";
import { URL_CONFIG } from "../_constant/config/url_config";
import { toast } from "react-toastify";
import moment from "moment";

function Download() {
  const [sdate, setSdate] = useState("2022-01-01");
  const [edate, setEdate] = useState(moment().format("YYYY-MM-DD"));

  const downloadFile = async (e) => {
    e.preventDefault();
    if(sdate==""){
      toast.error("Start date is required")
      return
    }
    if(edate==""){
      toast.error("End date is required")
      return
    }
    const token = localStorage.getItem("authToken");
    let a = document.createElement("a");
    // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
    a.href = `${URL_CONFIG.DEV_URL}export-excel?token=${token}&startDate=${sdate}&endDate=${edate}`;
    a.download = "data.xlsx";
    a.target = "blank";
    a.click();
  };

  return (
    <Form className="m-3">
      <FormGroup>
        <Label>Start Date</Label>
        <Input
          type="date"
          name="sdate"
          value={sdate}
          onChange={(e) => {
            setSdate(e.target.value);
          }}
          autoComplete="off"
        />
      </FormGroup>
      <FormGroup>
      <Label>End Date</Label>
        <Input
          type="date"
          name="edate"
          value={edate}
          onChange={(e) => {
            setEdate(e.target.value);
          }}
          placeholder="End Date"
          autoComplete="off"
        />
      </FormGroup>
     
      <FormGroup className="button_div mt-5">
        <Button className="all-btn" onClick={downloadFile}>
       Download Excel
        </Button>
      </FormGroup>

    </Form>
  );
}

export default Download;
