import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Form, FormGroup, Input, Button, Col, Row } from "reactstrap";
import { Getbyid, reschedulebyid } from "../_helper/apiCall/leadServices";
import { toast } from "react-toastify";

function Reschedule(props) {
  const [showButton, setShowButton] = useState(true);
  const [age, setAge] = useState();
  const [queries, setQueries] = useState();
  const [rdate, setRdate] = useState();
  const [comment, setComment] = useState();
  const [leadstatus, setLeadstatus] = useState();
  useEffect(() => {
    (async () => {
      const data = await Getbyid(props.id);
      if (data) {
        setLeadstatus(data?.data?.status);
        setComment(data?.data?.comment);
        setRdate(data?.data?.followup_date);
        setAge(data?.data?.age);
        setQueries(data?.data?.queries);
      }
    })();
  }, [props.id]);
  const statusoption = [
    { value: "F", label: "Follow up" },
    { value: "R", label: "Relevent" },
    { value: "CallNA", label: "Not Answered" },
    { value: "AptF", label: "Appointment Fix" },
    { value: "AptC", label: "Appointment Cancel" },
    { value: "Apt", label: "Appointment Done" },
    { value: "SurgF", label: "Surgery Fix" },
    { value: "W", label: "Surgery Done" },
    { value: "L", label: "Irrelevent" },
  ];
  const enquiryoptions = [
    { value: "Knock Knee", label: "Knock Knee" },
    { value: "U/L & B/L TKR", label: "U/L & B/L TKR" },
    { value: "U/L & B/L TKR", label: "U/L & B/L THR" },
    { value: "Knee Ligament Tear", label: "Knee Ligament Tear" },
    { value: "Hip Ligament Tear", label: "Hip Ligament Tear" },
    { value: "Knee Problem", label: "Knee Problem" },
    { value: "Hip Problem", label: "Hip Problem" },
    { value: "Spine Problem", label: "Spine Problem" },
    { value: "Foot Problem", label: "Foot Problem" },
    { value: "Shoulder Problem", label: "Shoulder Problem" },
    { value: "Others", label: " Others" },
  ];

  const statusoption2 = {
    F: "Follow up",
    R: "Relevent",
    CallNA: "Not Answered",
    AptF: "Appointment Fix",
    AptC: "Appointment Cancel",
    Apt: "Appointment Done",
    SurgF: "Surgery Fix",
    W: "Surgery Done",
    L: "Irrelevent",
  };
  const Submit = async (e) => {
    e.preventDefault();
    let obj = {
      rdate,
      leadstatus,
      comment,
      age,
      queries
    };
    if (!rdate || !leadstatus || !comment) {
      toast.error("all fields required");
      return;
    }
    let res = await reschedulebyid(props.id, obj);
    setShowButton(false);
    if (res?.status === 1) {
      props.setModal(true);
      toast.success(res.message);
      return;
    }
    setShowButton(true);
    toast.error(res?.message);
    return;
  };
  return (
    <Form className="m-3">
       <FormGroup>
        <Row>
          <Col>
   <Select
     onChange={(e) => {
       setQueries(e.value);
     }}
     placeholder="Treatment"
     options={enquiryoptions}
     autoComplete="off"
   />
   </Col>
   <Col>
   <Input
  type="number"
  name="age"
  value={age}
  onChange={(e) => {
    setAge(e.target.value);
  }}
  placeholder="Age"
  autoComplete="off"
/>
</Col>
</Row>
 </FormGroup>

      <FormGroup>
        <Select
          placeholder={statusoption2[leadstatus] || "select status"}
          options={statusoption}
          onChange={(e) => {
            setLeadstatus(e.value);
          }}
          autoComplete="off"
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="date"
          name="rdate"
          value={rdate}
          onChange={(e) => {
            setRdate(e.target.value);
          }}
          autoComplete="off"
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="textarea"
          name="comment"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          placeholder="Comment"
          autoComplete="off"
        />
      </FormGroup>
      {showButton && 
      <FormGroup className="button_div mt-5">
        <Button className="all-btn" onClick={Submit}>
          Reschedule
        </Button>
      </FormGroup>
}
    </Form>
  );
}

export default Reschedule;
