import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Container,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import { Getbyid, UpdateLead } from "../_helper/apiCall/leadServices";
import Navbar2 from "../components/navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getUsers } from "../_helper/apiCall/userData";
import { getUserlocal } from "../_helper/apiConfig/api_auth";

function Dealform(props) {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [authUser, setAuthUser] = useState(getUserlocal());
  const { id } = useParams();
  const [age, setAge] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  let [country, setCountry] = useState("");
  let [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [comment, setComment] = useState();
  const [followup_date, setFollowup_date] = useState();
  const [queries, setQueries] = useState();
  const [source, setSource] = useState();
  const [leaduser, setleadUser] = useState();
  const [user, setUser] = useState([]);
  const [leadstatus, setLeadstatus] = useState();

  
  useEffect(() => {
    (async () => {
      let res = await getUsers();
      if (res?.data) {
        setUser(
          res?.data
            ?.filter((i) => !i.isAdmin)
            .map((i) => ({ label: i.name, value: i.email }))
        );
      }
    })();

    // Aos.init({ duration: 1000 });
  }, []);
  useEffect(() => {
    (async () => {
      const data = await Getbyid(id);
      if (data) {
        setName(data?.data?.name);
        setAge(data?.data?.age);
        setEmail(data?.data?.email);
        setPhone(data?.data?.phone);
        setQueries(data?.data?.queries);
        setLeadstatus(data?.data?.status);
        setSource(data?.data?.source);
        setComment(data?.data?.comment);
        setFollowup_date(data?.data?.followup_date);
        setCity(data?.data?.city || "Select City");
        setCountry(data?.data?.country || "Select country");
        setState(data?.data?.state || "Select state");
      }
    })();
  }, [id]);

  const Submit = async (e) => {
    if(country=="Select country"){
      country=""
    }
    if(state=="Select state"){
      state=""
    }
    if(state=="Select City"){
      state=""
    }
    e.preventDefault();
    let obj = {
      name,
      age,
      queries,
      email,
      phone,
      source,
      followup_date,
      status:leadstatus,
      comment,
      user: leaduser,
      city,
      country,
      state,
    };

    if (
      !name ||
      !phone ||
      !source
    ) {
      toast.error("all fields required");
      return;
    }
    let res = await UpdateLead(id, obj);
    if (res?.status === 1) {
      navigate("/follow-up");
      toast.success(res.message);
      return;
    }
    toast.error(res?.message);
    return;
  };

  const enquiryoptions = [
    { value: "Knock Knee", label: "Knock Knee" },
    { value: "U/L & B/L TKR", label: "U/L & B/L TKR" },
    { value: "U/L & B/L TKR", label: "U/L & B/L THR" },
    { value: " Knee Ligament Tear", label: " Knee Ligament Tear" },
    { value: "Hip Ligament Tear", label: "Hip Ligament Tear" },
    { value: " Knee Problem", label: " Knee Problem" },
    { value: " Hip Problem", label: " Hip Problem" },
    { value: " Spine Problem", label: " Spine Problem" },
    { value: " Foot Problem", label: "Foot Problem" },
    { value: "  Shoulder Problem", label: "  Shoulder Problem" },
    { value: " Others", label: " Others" },
  ];

  const sourceoption = [
    { value: "Phone Call", label: "Phone Call" },
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "Website", label: "Website" },
    { value: "Message", label: "Message" },
    { value: "Mail", label: "Mail" },
    { value: "Reference", label: "Reference" },
    { value: "Campain", label: "Campain" },
    { value: "Others", label: "Others" },
  ];
  const statusoption = [
    { value: "F", label: "Follow up" },
    { value: "R", label: "Relevent" },
    { value: "CallNA", label: "Not Answered" },
    { value: "AptF", label: "Appointment Fix" },
    { value: "AptC", label: "Appointment Cancel" },
    { value: "Apt", label: "Appointment Done" },
    { value: "SurgF", label: "Surgery Fix" },
    { value: "W", label: "Surgery Done" },
    { value: "L", label: "Irrelevent" },
  ];

  const statusoption2 = {
    F: "Follow up",
    R: "Relevent",
    CallNA:"Not Answered",
    AptF: "Appointment Fix",
    AptC: "Appointment Cancel",
    Apt: "Appointment Done",
    SurgF: "Surgery Fix",
    W: "Surgery Done",
    L: "Irrelevent",
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Update Lead</title>
        </Helmet>
      </HelmetProvider>
      <Navbar2 />
      <Container className="add-deal-container ">
        <Row className="justify-content-center align-items-center">
          <Col md={6}>
            <div className="shadow-lg p-3 mb-5 rounded" data-aos="fade-left">
              <h2 className="text-center m-4">Edit Lead</h2>
              <Form className="m-3">
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        required
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        disabled={!authUser?.isAdmin}
                        placeholder="Full Name"
                        autocomplete="off"
                      />
                    </Col>
                    <Col>
                      <Input
                        type="number"
                        name="age"
                        value={age}
                        onChange={(e) => {
                          setAge(e.target.value);
                        }}
                        placeholder="Age"
                        autocomplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        disabled={!authUser?.isAdmin}
                        placeholder="Email Address"
                        autocomplete="off"
                      />
                    </Col>
                    <Col>
                      <Input
                        required
                        type="text"
                        name="number"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        disabled={!authUser?.isAdmin}
                        placeholder="Phone Number"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <CountrySelect
                        onChange={(e) => {
                          setCountryid(e.id);
                          setCountry(e.name);
                        }}
                        placeHolder={country}
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <StateSelect
                        countryid={countryid}
                        onChange={(e) => {
                          setStateid(e.id);
                          setState(e.name);
                        }}
                        placeHolder={state}
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <CitySelect
                        countryid={countryid}
                        stateid={stateid}
                        onChange={(e) => {
                          setCity(e.name);
                        }}
                        placeHolder={city}
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Select
                        onChange={(e) => {
                          setQueries(e.value);
                        }}
                        placeholder={queries || "Treatment Enquired for"}
                        options={enquiryoptions}
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <Select
                         disabled={!authUser?.isAdmin}
                        placeholder={source || "Select Source"}
                        options={sourceoption}
                        onChange={(e) => {
                          setSource(e.value);
                        }}
                     
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        type="textarea"
                        name="comment"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        placeholder="Enter Your Comment"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <Select
                        placeholder={
                          statusoption2[leadstatus] || "select status"
                        }
                        options={statusoption}
                        onChange={(e) => {
                          setLeadstatus(e.value);
                         
                        }}
                        
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        type="date"
                        name="date"
                        value={followup_date}
                        onChange={(e) => {
                          setFollowup_date(e.target.value);
                        }}
                
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                {authUser?.isAdmin && (
                  <FormGroup>
                    <Row>
                      <Col>
                        <Select
                          onChange={(e) => {
                            setleadUser(e.value);
                          }}
                          placeholder="Select User"
                          options={user}
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                )}
                <FormGroup className="button_div mt-5">
                  <Button className="all-btn" onClick={Submit}>
                    Save Changes
                  </Button>
                  <Button
                    className="all-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/follow-up");
                    }}
                  >
                    Back
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dealform;
