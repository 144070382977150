import React, { useEffect, useState } from "react";
import {Chart} from "react-google-charts"
import { countForPi } from "../_helper/apiCall/leadServices";

const data1 = [
    ["Task", "Hours per Week"],
    ["Meeting", 0],
    ["Followup", 0],
    ["Won Deal", 0],
    ["Lost Deal", 0],
  ];
  
  const options = {
    legend:'left',  //labeled, top, bottom, right, left
    title: "My Monthly Activities",
    is3D: true,
    slices: {
      0: { color: "#045d6c" },
      1: { color: "blue" },
      2: { color: "green" },
      3: { color: "red" },
      4:{ color: "orange"}
    },
  };

function Piechart() {
  const[data,setData]=useState([...data1])
    useEffect(()=>{
      (async()=>{
       const res= await countForPi()
       console.log("res",res);
       if(res){
       setData([
        ["Task", "Work per Month"],
        ["Followup", res.followup],
        ["Appointment", res.appointmentDone],
        ["Won Deal", res.wonleads],
        ["Lost Deal", res.lostLeads],
        ["Not Answered", res.NotAnswered],
 
      ])}
      })()
    },[])
    return(
   
   
  <Chart
  chartType="PieChart"
  width={"100%"}
  height={"500px"}
  data={data}
options={options}
  />
    )
}

export default Piechart;