import React, { useState } from "react";
import Navbar2 from "../components/navbar";
import Userdata from "../common/user_data";
import { Button } from "reactstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Followup() {
  const [tab, setTab] = useState("followup");
  const [activeButton, setActiveButton] = useState("2");
  // setActiveButton(value)
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Follw Up</title>
        </Helmet>
      </HelmetProvider>
      <Navbar2 />

      <Userdata />
    </>
  );
}

export default Followup;
