import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText,
} from "reactstrap";
import { FaUser, FaLock } from "react-icons/fa"; // Importing icons from React Icons
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { signUp } from "../_helper/apiCall/authService";


function Signup() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [cpassword, setCpassword] = useState();
  const navigate = useNavigate();

  const onSubmit =async (e) => {
    e.preventDefault();
    if (cpassword !== password) {
      toast.error("Password and confirm password should be same");
      return;
    }

   
    // login logic here, like sending data to a server
    let obj = { name: name, phone: phone, email: email, password: password };
    if (!name || !phone || !email || !password) {
      toast.error("all fields are required");
      return;
    }

    let res =await signUp(obj);
    if (res && res.status == 1) {
      navigate("/login");
      toast.success(res.message);
    } else {
      toast.error(res?.message);
    }
  };

  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>CMK Signup</title>
      </Helmet>
    </HelmetProvider>
    <Container className="login-container ">
      <Row className="justify-content-center align-items-center">
        <Col md={6}>
          <div className="shadow-lg p-5 mb-5 rounded" data-aos="fade-up">
            <h2 className="text-center m-4">Signup</h2>
            <Form onSubmit={onSubmit}>
              <FormGroup className="mb-3">
                <Label for="name" className="input-label">
                  <FaUser className="icon" /> Name
                </Label>
                <Input
                  type="text"
                  id="name"
                  placeholder="Enter your Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="phone" className="input-label">
                  <FaPhone className="icon" /> Phone
                </Label>
                <Input
                  type="number"
                  id="phone"
                  maxLength={10}
                  minLength={10}
                  placeholder="Enter your Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="email" className="input-label">
                  <MdEmail className="icon" /> Email
                </Label>
                <Input
                  type="email"
                  id="email"
                  placeholder="Enter your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="password" className="input-label">
                  <FaLock className="icon" /> Password
                </Label>
                <Input
                  type="password"
                  id="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="Cpassword" className="input-label">
                  <FaLock className="icon" /> Confirm Password
                </Label>
                <Input
                  type="password"
                  id="cpassword"
                  placeholder="Confirm Your Password"
                  value={cpassword}
                  onChange={(e) => setCpassword(e.target.value)}
                />
              </FormGroup>

              <Button block className="all-btn mt-5" onClick={onSubmit}>
                Signup
              </Button>

              <FormText className="mt-3 text-center">
                Already Registered ?
                <Link to="/login">Click here to login</Link>
              </FormText>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Signup;
