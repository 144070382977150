import API_AUTH from "../apiConfig/api_auth";

export const RestMethod={
GET,
PUT,
POST,
DELETE
};


/** 
*
* @GET {*} url
* @param {*} options
*/

async function GET(url, options ={}){
const response=await API_AUTH.get(url , {...options});
return response;
}

/**
 * 
 * @POST {*} url
 * @param {*} options
 * @param {*} data
 */

async function POST(url,data,options ={}){
    const response=await API_AUTH.post(url,data, {...options});
    return response;
}

/**
 * 
 * @PUT {*} URL
 * @param {*} data
 * @param {*} options
 */

async function PUT (url, data, options={}){
    const response=await API_AUTH.put(url,data,{...options});
    return response;
}

/**
 * 
 * @DELETE {*} url
 * @param {*} data@param {*} options
 */

async function DELETE(url,data,options={}){
    const response=await API_AUTH.delete(url,data,{...options})
    return response;
}


export default RestMethod;