import React, { useEffect , useState} from "react";
import { Row, Col } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Aos from "aos";
import "aos/dist/aos.css";
import Piechart from "../components/piechart";
import Barchart from "../components/barchart";
import Navbar2 from "../components/navbar";
import Allcards from "../components/card";
function Home() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>
          Dashboard
        </title>
      </Helmet>
    </HelmetProvider>
      <Navbar2 />
 
      <div  className="m-4">
        <Allcards />
        <Row xs="1" md="2" sm="2">
          <Col className="shadow mb-5" data-aos="fade-right">
            <Barchart />
          </Col>
          <Col className="shadow mb-5" data-aos="fade-left">
            <Piechart />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
