import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Select from "react-select";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Navbar2 from "./navbar";
import { getUsers } from "../_helper/apiCall/userData";
import { getUserlocal } from "../_helper/apiConfig/api_auth";
import { AddLead } from "../_helper/apiCall/leadServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

function Assignleads() {
  const [showButton, setShowButton] = useState(true);
  const [authUser, setAuthUser] = useState(getUserlocal());
  const [modal, setModal] = useState(false);
  const [id, setId] = useState();
  const navigate = useNavigate();
  const [existuser, setExistuser] = useState();
  const [assigndate, setAssigndate] = useState();
  const toggle = () => setModal(!modal);
  const [leadinfo, setLeadinfo] = useState("");
  const [comment, setComment] = useState("");
  const [leaduser, setleadUser] = useState();
  const [source, setSource] = useState();
  const [user, setUser] = useState([]);
  const [age, setAge] = useState();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [queries, setQueries] = useState();
  const [followup_date, setFollowup_date] = useState();
  const [leadstatus, setLeadstatus] = useState("New");
  const sourceoption = [
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "Phone Call", label: "Phone Call" },
    { value: "Website", label: "Website" },
  ];

  useEffect(() => {
    (async () => {
      let res = await getUsers();
      if (res?.data) {
        setUser(
          res?.data
            ?.filter((i) => !i.isAdmin)
            .map((i) => ({ label: i.name, value: i.email }))
        );
      }
    })();

    // Aos.init({ duration: 1000 });
  }, []);
  const assign = async (e) => {
    setShowButton(false);
    e.preventDefault();
    let obj = {
      name,
      age,
      queries,
      email,
      phone,
      source,
      country,
      state,
      city,
      followup_date,
      leadstatus,
      comment,
      user: leaduser,
    };
    if (!name || !email || !phone || !source || !city || !leaduser) {
      toast.error("all fields required");
      setShowButton(true);
      return;
    }
    let res = await AddLead(obj);
    if (res?.status === 1) {
      toast.success(res.message);
      setShowButton(true);
      return;
    }
    console.log(res?.data);
    setModal(true);
    toast.error(res?.message);
    setExistuser(res?.data.user);
    setAssigndate(res?.data.createdAt);
    setId(res?.data.id);
    setShowButton(true);
    return;
  };

  const emailRegex = /Email\s+([^\s]+)/;
  const fullNameRegex = /Full name\s+([^\n]+)/;
  const phoneRegex = /Phone number\s+([^\s]+)/;
  const cityRegex = /City\s+([^\n]+)/;

  // Extracting the information
  const emailMatch = leadinfo.match(emailRegex);
  const fullNameMatch = leadinfo.match(fullNameRegex);
  const phoneMatch = leadinfo.match(phoneRegex);
  const cityMatch = leadinfo.match(cityRegex);

  // Getting the captured groups or setting default values if no match is found
  const email = emailMatch ? emailMatch[1] : "Not found";
  const name = fullNameMatch ? fullNameMatch[1] : "Not found";
  const phone = phoneMatch ? phoneMatch[1] : "Not found";
  const city = cityMatch ? cityMatch[1] : "Not found";

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Assign Lead</title>
        </Helmet>
      </HelmetProvider>
      <Navbar2 />
      <Container className="add-deal-container ">
        <Row className="justify-content-center align-items-center">
          <Col md={6}>
            <div className="shadow-lg p-3 mb-5 rounded">
              <h2 className="text-center m-4">Assign Lead</h2>
              <Form className="m-3">
                <FormGroup>
                  <Input
                    style={{ height: "250px" }}
                    type="textarea"
                    value={leadinfo}
                    onChange={(e) => {
                      setLeadinfo(e.target.value);
                    }}
                    placeholder="Enter Your Lead Data"
                    autoComplete="off"
                  />
                </FormGroup>
                <FormGroup>
                  <Select
                    onChange={(e) => {
                      setSource(e.value);
                    }}
                    placeholder="Select Source"
                    options={sourceoption}
                    autoComplete="off"
                  />
                </FormGroup>
                <FormGroup>
                  <Select
                    onChange={(e) => {
                      setleadUser(e.value);
                    }}
                    placeholder="Select User"
                    options={user}
                    autoComplete="off"
                  />
                </FormGroup>
                {showButton && (
                  <FormGroup className="button_div mt-5">
                    <Button className="all-btn" onClick={assign}>
                      Assign
                    </Button>
                  </FormGroup>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader toggle={toggle} className="adddeal-popup">
          This Lead is already assigned to {existuser}  on{" "}
          {assigndate ? moment(assigndate).format("DD-MM-YYYY") : ""}
        </ModalHeader>
        {authUser?.isAdmin && (
          <ModalBody className="adddeal-popup">
            If you want to change this{" "}
            <Link
              onClick={(e) => {
                e.preventDefault();
                navigate(`/edit-lead/${id}`);
              }}
            >
              click here
            </Link>
          </ModalBody>
        )}
      </Modal>
    </>
  );
}

export default Assignleads;
