import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import Aos from "aos";
import "aos/dist/aos.css";
import "react-country-state-city/dist/react-country-state-city.css";
import Navbar2 from "../components/navbar";
import { toast } from "react-toastify";
import { AddLead } from "../_helper/apiCall/leadServices";
import { getUsers } from "../_helper/apiCall/userData";
import { getUserlocal } from "../_helper/apiConfig/api_auth";
import { Helmet, HelmetProvider } from "react-helmet-async";
import moment from "moment";

function AddDeal() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [id, setId] = useState();
  const [existuser, setExistuser] = useState();
  const [assigndate, setAssigndate] = useState();
  const [authUser, setAuthUser] = useState(getUserlocal());
  const [name, setName] = useState();
  const [age, setAge] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [comment, setComment] = useState();
  const [queries, setQueries] = useState();
  const [source, setSource] = useState();
  const [followup_date, setFollowup_date] = useState();
  const [user, setUser] = useState([]);
  const [leaduser, setleadUser] = useState();
  const [leadstatus, setLeadstatus] = useState();

  const enquiryoptions = [
    { value: "Knock Knee", label: "Knock Knee" },
    { value: "U/L & B/L TKR", label: "U/L & B/L TKR" },
    { value: "U/L & B/L TKR", label: "U/L & B/L THR" },
    { value: "Knee Ligament Tear", label: "Knee Ligament Tear" },
    { value: "Hip Ligament Tear", label: "Hip Ligament Tear" },
    { value: "Knee Problem", label: "Knee Problem" },
    { value: "Hip Problem", label: "Hip Problem" },
    { value: "Spine Problem", label: "Spine Problem" },
    { value: "Foot Problem", label: "Foot Problem" },
    { value: "Shoulder Problem", label: "Shoulder Problem" },
    { value: "Others", label: " Others" },
  ];

  const sourceoption = [
    { value: "Phone Call", label: "Phone Call" },
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "Website", label: "Website" },
    { value: "Message", label: "Message" },
    { value: "Mail", label: "Mail" },
    { value: "Reference", label: "Reference" },
    { value: "Campain", label: "Campain" },
    { value: "Others", label: "Others" },
  ];
  const statusoption = [
    { value: "F", label: "Follow up" },
    { value: "R", label: "Relevent" },
    { value: "CallNA", label: "Not Answered" },
    { value: "AptF", label: "Appointment Fix" },
    { value: "AptC", label: "Appointment Cancel" },
    { value: "Apt", label: "Appointment Done" },
    { value: "SurgF", label: "Surgery Fix" },
    { value: "W", label: "Surgery Done" },
    { value: "L", label: "Irrelevent" },
  ];

  useEffect(() => {
    (async () => {
      let res = await getUsers();
      if (res?.data) {
        setUser(
          res?.data
            ?.filter((i) => !i.isAdmin)
            .map((i) => ({ label: i.name, value: i.email }))
        );
      }
    })();

    Aos.init({ duration: 1000 });
  }, []);

  const Submit = async (e) => {
    e.preventDefault();
    let obj = {
      name,
      age,
      queries,
      email,
      phone,
      source,
      country,
      state,
      city,
      followup_date,
      leadstatus,
      comment,
      user: leaduser,
    };
    if (
      !name ||
      !queries ||
      !phone ||
      !source ||
      !followup_date ||
      !leadstatus ||
      !comment
    ) {
      toast.error("all fields required");
      return;
    }
    if (authUser?.isAdmin && !leaduser) {
      toast.error("all fields required");
      return;
    }
    let res = await AddLead(obj);
    if (res?.status === 1) {
      navigate("/home");
      toast.success(res.message);
      return;
    }
    setModal(true);
    setExistuser(res?.data.user);
    setAssigndate(res?.data.createdAt);
    setId(res?.data.id);
    toast.error(res?.message);
    return;
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Add Lead</title>
        </Helmet>
      </HelmetProvider>
      <Navbar2 />
      <Container className="add-deal-container ">
        <Row className="justify-content-center align-items-center">
          <Col md={6}>
            <div className="shadow-lg p-3 mb-5 rounded" data-aos="fade-left">
              <h2 className="text-center m-4">Add Lead</h2>
              <Form className="m-3">
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        required
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        placeholder="Full Name"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <Input
                        type="number"
                        name="age"
                        value={age}
                        onChange={(e) => {
                          setAge(e.target.value);
                        }}
                        placeholder="Age"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Email Address"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <Input
                        required
                        type="text"
                        name="number"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        placeholder="Phone Number"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <CountrySelect
                        onChange={(e) => {
                          setCountryid(e.id);
                          setCountry(e.name);
                        }}
                        placeHolder="Select Country"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <StateSelect
                        countryid={countryid}
                        onChange={(e) => {
                          setStateid(e.id);
                          setState(e.name);
                        }}
                        placeHolder="Select State"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <CitySelect
                        countryid={countryid}
                        stateid={stateid}
                        onChange={(e) => {
                          setCity(e.name);
                        }}
                        placeHolder="Select City"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Select
                        onChange={(e) => {
                          setQueries(e.value);
                        }}
                        placeholder="Treatment Enquired for"
                        options={enquiryoptions}
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <Select
                        onChange={(e) => {
                          setSource(e.value);
                        }}
                        placeholder="Select Source"
                        options={sourceoption}
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        type="date"
                        name="date"
                        value={followup_date}
                        onChange={(e) => {
                          setFollowup_date(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <Select
                        onChange={(e) => {
                          setLeadstatus(e.value);
                        }}
                        placeholder="Status"
                        options={statusoption}
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        type="textarea"
                        name="comment"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        placeholder="Enter Your Comment"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </FormGroup>
                {authUser?.isAdmin && (
                  <FormGroup>
                    <Row>
                      <Col>
                        <Select
                          onChange={(e) => {
                            setleadUser(e.value);
                          }}
                          placeholder="Select User"
                          options={user}
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                )}
                <FormGroup className="button_div mt-5">
                  <Button className="all-btn" onClick={Submit}>
                    Add Lead
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader toggle={toggle} className="adddeal-popup">
          This Lead is already assigned to {existuser} on{" "}
          {assigndate ? moment(assigndate).format("DD-MM-YYYY") : ""}
        </ModalHeader>
        {authUser?.isAdmin && (
          <ModalBody className="adddeal-popup">
            If you want to change this{" "}
            <Link
              onClick={(e) => {
                e.preventDefault();
                navigate(`/edit-lead/${id}`);
              }}
            >
              edit here
            </Link>
          </ModalBody>
        )}
      </Modal>
    </>
  );
}

export default AddDeal;
